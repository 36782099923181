import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { IoIosArrowDown } from "react-icons/io";

// Reusable component for form fields
const FormField = ({ label, name, value, onChange, unit }) => {
  const handleDecrement = () => {
    if (value > 0) {
      onChange(name, value - 1);
    }
  };

  const handleIncrement = () => {
    if (value < 100) {
      onChange(name, value + 1);
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <FormControl variant="outlined" fullWidth>
        <Typography
          variant="body1"
          sx={{ fontSize: "12px", mb: 0.5, textWrap: "nowrap" }}
        >
          {label}
          {["Chambres", "Salle de bain"].includes(label) && (
            <span style={{ color: "#EC544B" }}>*</span>
          )}
        </Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
          sx={{
            width: "100%",
            maxWidth: "522px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "100px",
              width: "47px",
              height: "44px",
              minWidth: 0,
              boxShadow: "none",
              background: value <= 0 ? "none" : "#FFFFFF",
              fontSize: "16px",
              border:
                value <= 0
                  ? "0.5px solid rgba(132, 159, 186, 0.45)"
                  : "0.5px solid #849FBA",
              color: value <= 0 ? "rgba(132, 159, 186, 0.45)" : "#263238",
              "&:hover": {
                background: "#FFFFFF",
                border:
                  value <= 0 ? "0.5px solid #0066EE" : "0.5px solid #0066EE",
                color: value <= 0 ? "#0066EE" : "#0066EE",
                boxShadow: "none",
              },
              "&:disabled": {
                background: "#FFFFFF", // Change the disabled background color here
                opacity: 0.8,
                color: "rgba(132, 159, 186, 0.45)", // Change the disabled text color here
              },
            }}
            disabled={value <= 0}
            onClick={handleDecrement}
          >
            -
          </Button>

          <TextField
            placeholder={`Exemple : ${value}`}
            variant="outlined"
            fullWidth
            type="number"
            value={value}
            onChange={(e) => {
              let newValue = parseInt(e.target.value);
              if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
                onChange(name, newValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 45,
                borderRadius: "22.5px",
                "& fieldset": {
                  borderColor: "rgba(132, 159, 186, 0.45)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(132, 159, 186, 0.45)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(132, 159, 186, 0.45)",
                },
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: "100px",
              width: "47px",
              height: "44px",
              minWidth: 0,
              boxShadow: "none",
              background: "#FFFFFF",
              fontSize: "16px",
              border: "0.5px solid #849FBA",
              color: "#263238",
              "&:hover": {
                background: "#FFFFFF",
                border: "0.5px solid #0066EE",
                color: "#0066EE",
                boxShadow: "none",
              },
              "&:disabled": {
                background: "#FFFFFF", // Change the disabled background color here
                color: "rgba(132, 159, 186, 0.45)", // Change the disabled text color here
              },
            }}
            disable={value >= 100}
            onClick={handleIncrement}
          >
            +
          </Button>
        </Box>
      </FormControl>
    </Grid>
  );
};

export default function InformationsPrincipalesDuBien({
  formData,
  setFormData,
  handleItemClick,
}) {
  const names = ["Loti", "Titré"];
  const [personName, setPersonName] = useState([]);

  const typeHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(value);
    setFormData((prevData) => ({
      ...prevData,
      features: value.map(
        (item) => item.charAt(0).toLowerCase() + item.slice(1)
      ),
    }));
  };
  return (
    <Box mt={6}>
      <Typography
        variant="body1"
        fontSize={24}
        fontWeight={600}
        color={"#263238"}
        gutterBottom
      >
        Informations principales du bien
      </Typography>

      <Grid
        mt={3}
        component="form"
        autoComplete="off"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "22.5px",
            backgroundColor: "#FFFFFF",
            "& fieldset": {
              border: "1px solid rgba(132, 159, 186, 0.45) !important",
              // borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&:hover fieldset": {
              // borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&.Mui-focused fieldset": {
              // borderColor: "rgba(132, 159, 186, 0.45)",
            },
          },
        }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 2, sm: 3, md: 4 }}
      >
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Surface affichée
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              placeholder="Exemple : 120"
              id="outlined-start-adornment"
              name="area"
              type="number"
              inputProps={{
                min: 0,
              }}
              value={formData.area || ""}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numbers and prevent negative values
                if (/^\d*$/.test(value)) {
                  handleItemClick(e.target.name, value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m²</InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          {formData["property_type"] === "terrain" && (
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                Zoning
                <span style={{ color: "#EC544B" }}>*</span>
              </Typography>
              <Select
                size="small"
                name="zoning"
                value={formData.zoning || "choisir"}
                onChange={(e) => handleItemClick(e.target.name, e.target.value)}
                MenuProps={{
                  classes: {
                    paper: "custom-menu-paper",
                    list: "custom-menu-list",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: 45,
                    borderRadius: "22.5px",
                    "& fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                  },
                }}
              >
                <MenuItem value="choisir" sx={{ display: "none" }}>
                  Choisir
                </MenuItem>
                <MenuItem value="agricole">Agricole</MenuItem>
                <MenuItem value="immeuble">Immeuble</MenuItem>
                <MenuItem value="industriel">Industriel</MenuItem>
                <MenuItem value="maison">Maison</MenuItem>
                <MenuItem value="service_public">Service Public</MenuItem>
                <MenuItem value="villa">Villa</MenuItem>
              </Select>
            </FormControl>
          )}
          {formData["property_type"] !== "parking" &&
            formData["property_type"] !== "terrain" && (
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                  Âge du bien
                </Typography>
                <Select
                  size="small"
                  name="age_of_property"
                  value={formData.age_of_property || "choisir"}
                  onChange={(e) =>
                    handleItemClick(e.target.name, e.target.value)
                  }
                  MenuProps={{
                    classes: {
                      paper: "custom-menu-paper",
                      list: "custom-menu-list",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 45,
                      borderRadius: "22.5px",
                      "& fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                    },
                  }}
                >
                  <MenuItem value="choisir" sx={{ display: "none" }}>
                    Choisir
                  </MenuItem>
                  <MenuItem value="neuf">Neuf</MenuItem>
                  <MenuItem value="1 5 ans">1-5 ans</MenuItem>
                  <MenuItem value="6 10 ans">6-10 ans</MenuItem>
                  <MenuItem value="11 20 ans">11-20 ans</MenuItem>
                  <MenuItem value="21+ ans">21+ ans</MenuItem>
                  <MenuItem value="je ne sais pas">Je ne sais pas</MenuItem>
                </Select>
              </FormControl>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {formData["property_type"] === "terrain" && (
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                Caractéristiques
              </Typography>
              <Select
                multiple
                className="features"
                displayEmpty
                value={personName || []}
                onChange={typeHandleChange}
                IconComponent={IoIosArrowDown}
                MenuProps={{
                  classes: {
                    paper: "custom-menu-paper",
                    list: "custom-menu-list",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "22.5px",
                    "& fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                  },
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Box>Choisir</Box>;
                  }
                  return selected.join(", ");
                }}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {formData["property_type"] !== "parking" &&
            formData["property_type"] !== "terrain" && (
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                  État Du Bien
                </Typography>
                <Select
                  size="small"
                  name="condition_of_property"
                  value={formData.condition_of_property || "choisir"}
                  onChange={(e) =>
                    handleItemClick(e.target.name, e.target.value)
                  }
                  MenuProps={{
                    classes: {
                      paper: "custom-menu-paper",
                      list: "custom-menu-list",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 45,
                      borderRadius: "22.5px",
                      "& fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(132, 159, 186, 0.45)",
                      },
                    },
                  }}
                >
                  <MenuItem value="choisir" sx={{ display: "none" }}>
                    Choisir
                  </MenuItem>
                  <MenuItem value="neuf">Neuf</MenuItem>
                  <MenuItem value="correct">Correct</MenuItem>
                  <MenuItem value="refait a neuf">Refait à neuf</MenuItem>
                  <MenuItem value="travaux a prevoir">
                    Travaux à prévoir
                  </MenuItem>
                </Select>
              </FormControl>
            )}
        </Grid>

        {/* Chambres */}
        {formData["property_type"] !== "terrain" &&
          formData["property_type"] !== "parking" &&
          formData["property_type"] !== "bureau" &&
          formData["property_type"] !== "local Commercial" &&
          formData["property_type"] !== "fonds de Commerce" &&
          formData["property_type"] !== "studio" && (
            <FormField
              label="Chambres"
              name="rooms"
              value={formData.rooms || 0}
              onChange={handleItemClick}
            />
          )}

        {/* Salle de bain */}
        {formData["property_type"] !== "terrain" &&
          formData["property_type"] !== "parking" && (
            <FormField
              label="Salle de bain"
              name="bathrooms"
              value={formData.bathrooms || 0}
              onChange={handleItemClick}
            />
          )}

        {/* Salons */}
        {formData["property_type"] !== "terrain" &&
          formData["property_type"] !== "parking" &&
          formData["property_type"] !== "immeuble" &&
          formData["property_type"] !== "bureau" &&
          formData["property_type"] !== "local Commercial" &&
          formData["property_type"] !== "fonds de Commerce" && (
            <FormField
              label="Salons"
              name="fairs"
              value={formData.fairs || 0}
              onChange={handleItemClick}
            />
          )}

        {/* Étage */}
        {formData["property_type"] !== "terrain" &&
          formData["property_type"] !== "parking" &&
          formData["property_type"] !== "maison" &&
          formData["property_type"] !== "immeuble" &&
          formData["property_type"] !== "villa" &&
          formData["property_type"] !== "riad" && (
            <FormField
              label="Étage"
              name="floors_level"
              value={formData.floors_level || 0}
              onChange={handleItemClick}
            />
          )}

        {/* Nombre d'étages dans l'immeuble */}
        {formData["property_type"] !== "terrain" &&
          formData["property_type"] !== "parking" &&
          formData["property_type"] !== "maison" &&
          formData["property_type"] !== "bureau" &&
          formData["property_type"] !== "local Commercial" &&
          formData["property_type"] !== "fonds de Commerce" &&
          formData["property_type"] !== "studio" &&
          formData["property_type"] !== "villa" &&
          formData["property_type"] !== "riad" && (
            <FormField
              label="Nombre d'étages dans l'immeuble"
              name="floors_count"
              value={formData.floors_count || 0}
              onChange={handleItemClick}
            />
          )}
      </Grid>
    </Box>
  );
}
