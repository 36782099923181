import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { toast } from "react-toastify";
import PublishCard from "./PublishCard";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { IoSearch } from "react-icons/io5";
import { TbCurrentLocation } from "react-icons/tb";
import StepOtpVerification from "../StepOtpVerification";

export default function StepOne({ formData, setFormData, handleClick }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.up("900"));
  const [address, setAddress] = useState(formData?.address || "");

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [marker, setMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 31.7917, lng: -7.0926 });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    version: "weekly",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
    language: "fr",
  });

  useEffect(() => {
    if (formData?.latitude && formData?.longitude && formData?.street_check) {
      setMarker({ lat: formData.latitude, lng: formData.longitude });
      setCenter({ lat: formData.latitude, lng: formData.longitude });
    } else if (
      formData?.latitude &&
      formData?.longitude &&
      formData.street_check === undefined
    ) {
      setMarker({ lat: formData.latitude, lng: formData.longitude });
      setCenter({ lat: formData.latitude, lng: formData.longitude });
    } else {
      setMarker(null);
    }
  }, [formData?.street_check]);

  useEffect(() => {
    if (map && Object.keys(formData).length > 0) {
      setCenter({ lat: formData?.latitude, lng: formData?.longitude });
      map.panTo({ lat: formData?.latitude, lng: formData?.longitude });
      setTimeout(() => map.setZoom(16));
    }
    if (isLoaded) {
      const autocompleteInstance = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        {
          componentRestrictions: { country: "ma" },
        }
      );
      setAutocomplete(autocompleteInstance);

      autocompleteInstance.addListener("place_changed", () => {
        const place = autocompleteInstance.getPlace();
        if (place.geometry) {
          const selectedLocation = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setCenter(selectedLocation);
          let check = false;
          let formattedAddress = place.formatted_address;
          const plusCodeComponent = place.address_components.find(
            (component) =>
              component.types.includes("street-address") ||
              component.types.includes("street_number") ||
              component.types.includes("plus_code")
          );
          const plusCode = plusCodeComponent ? plusCodeComponent.long_name : "";
          if (plusCode && formattedAddress.includes(plusCode)) {
            formattedAddress = formattedAddress
              .replace(`${plusCode}, `, "")
              .replace(plusCode, "");
            check = true;
            setMarker(selectedLocation);
            setCenter(selectedLocation);
            map.setZoom(17);
          }
          setAddress(formattedAddress);
          if (map.panTo) {
            map.panTo(selectedLocation);
            map.setZoom(16);
          }
          updateFormData(place, formattedAddress, selectedLocation, check);
        }
      });
    }
  }, [isLoaded, map, setFormData]);

  const moroccoBounds = {
    north: 36.0505269, // max lat
    south: 20.0, // Adjusted min lat value to include more southern cities
    east: -0.998429, // max lng
    west: -17.2551456, // min lng
  };

  const onMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const selectedLocation = { lat, lng };
    setMarker(selectedLocation);
    setCenter(selectedLocation);
    getAddressFromLatLng(lat, lng);
    map.setZoom(17);
  };

  const getAddressFromLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        let formattedAddress = results[0].formatted_address;
        const plusCodeComponent = results[0].address_components.find(
          (component) =>
            component.types.includes("street-address") ||
            component.types.includes("street_number") ||
            component.types.includes("plus_code")
        );
        const plusCode = plusCodeComponent ? plusCodeComponent.long_name : "";
        if (plusCode && formattedAddress.includes(plusCode)) {
          formattedAddress = formattedAddress
            .replace(`${plusCode}, `, "")
            .replace(plusCode, "");
        }
        setAddress(formattedAddress);
        updateFormData(results[0], formattedAddress, { lat, lng }, true);
      }
    });
  };

  const updateFormData = (place, formattedAddress, selectedLocation, check) => {
    const updatedFormData = {
      address: formattedAddress,
      latitude: selectedLocation.lat,
      longitude: selectedLocation.lng,
      location: `${selectedLocation.lat},${selectedLocation.lng}`,
      geo_location: { lat: selectedLocation.lat, lon: selectedLocation.lng },
      street_check: check,
      region: null,
      region_code: null,
      country: "morocco",
      city: null,
      commune: null,
      province: null,
      quartier: null,
    };
    const regions = {
      oriental: "oriental",
      "casablanca-settat": "casablanca",
      "marrakesh-safi": "marrakech",
      "drâa-tafilalet": "draa",
      "fès-meknès": "fes",
      "rabat-salé-kénitra": "rabat",
      "béni mellal-khénifra": "beni",
      "souss-massa": "souss",
      "guelmim-oued": "guelmim",
      "laâyoune-sakia el hamra": "laayoune",
      "dakhla-oued ed-dahab": "dakhla",
      "tanger-tétouan-al": "tanger",
    };
    place.address_components.forEach((component) => {
      const componentName = component.long_name.toLowerCase();
      const typeMap = {
        locality: "city",
        administrative_area_level_2: "commune",
        administrative_area_level_1: "province",
        sublocality: "quartier",
      };
      const mappedType = typeMap[component.types[0]];
      if (mappedType === "city")
        updatedFormData[
          mappedType
        ] = updatedFormData.city = updatedFormData.commune = componentName;
    });
    let addressString = place.address_components
      .map((item) => item.long_name.toLowerCase())
      .join(" ");
    Object.entries(regions).forEach(([regionKey, regionValue]) => {
      const cleanedRegionKey = regionKey.toLowerCase().replace(/[\s-]/g, "");
      const cleanedAddressString = addressString.replace(/[\s-]/g, "");
      if (
        cleanedAddressString.includes(cleanedRegionKey) ||
        cleanedAddressString.includes(regionValue)
      ) {
        updatedFormData.region = regionKey;
        updatedFormData.region_code = regionValue;
        // updatedFormData.province = regionValue;
      }
    });
    console.log("updatedFormData", updatedFormData);
    setFormData((prevFormData) => ({ ...prevFormData, ...updatedFormData }));
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };
          setCenter(currentLocation);
          setMarker(currentLocation);
          getAddressFromLatLng(latitude, longitude);
          map.setZoom(17);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleNext = () => {
    if (!address) {
      toast.error("L'adresse est requise");
      return;
    }
    handleClick("next");
  };
  return (
    <>
      <Box
        sx={{
          mt: 6,
          mb: 12,
        }}
      >
        <Grid container spacing={8} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography
                variant="body1"
                fontSize={24}
                fontWeight={600}
                color={"#263238"}
                gutterBottom
              >
                Adresse du bien
              </Typography>
              <Typography variant="body1" fontSize={16} color={"#263238"}>
                Entrez l'adresse du bien dans la barre de recherche.
              </Typography>
              <Box>
                <Paper
                  elevation={0}
                  variant="outlined"
                  component="form"
                  sx={{
                    borderRadius: "200px",
                    mt: 6,
                    mx: "auto",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: "space-between",
                      height: "67px",
                      width: "100%",
                    }}
                    id="DivBox"
                  >
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      <LocationOnIcon sx={{ color: "#0066EE" }} />
                    </InputAdornment>
                    <input
                      id="autocomplete"
                      type="text"
                      placeholder="Adresse du bien"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "20px 0",
                        border: "none",
                        borderRadius: "6px",
                        fontSize: "16px",
                        outline: "none",
                        ":hover": {
                          border: "none",
                        },
                        ":focus": {
                          border: "none",
                        },
                      }}
                    />
                    {!isMobileScreen ? (
                      <Box
                        onClick={() =>
                          marker === null
                            ? (function () {
                                toast.error(
                                  "Vous devez sélectionner une rue ou une adresse."
                                );
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  behavior: "smooth",
                                });
                              })()
                            : handleNext()
                        }
                        sx={{
                          width: "fit-content",
                          background: "#0066EE",
                          borderRadius: "50%",
                          padding: "12px",
                          boxShadow: "0 8px 20px 0 rgba(0, 94, 220, 0.12)",
                          display: "flex",
                          mr: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IoSearch color="#fff" size={20} />
                      </Box>
                    ) : (
                      <Button
                        className="common_btn"
                        variant="contained"
                        onClick={() =>
                          marker === null
                            ? (function () {
                                toast.error(
                                  "Vous devez sélectionner une rue ou une adresse."
                                );
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  behavior: "smooth",
                                });
                              })()
                            : handleNext()
                        }
                        sx={{
                          px: { sm: 5, md: 2 },
                          borderRadius: "100px",
                          background: "#0066EE",
                          boxShadow: "0 8px 20px 0 rgba(0, 94, 220, 0.12)",
                          fontSize: "16px !important",
                          fontWeight: "500 !important",
                          height: "56px",
                          width: "134px",
                          maxHeight: "none !important",
                          marginRight: "5px",
                          fontFamily: `"Inter", sans-serif`,
                        }}
                      >
                        Recherher
                      </Button>
                    )}
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              background:
                "url(https://s3-alpha-sig.figma.com/img/fd78/f384/914dcb7f51645e2d4e1fb92a043ad86f?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lhaxnrPwnfegrKJoCsJV38B3PTwyuKTmfMsukC8wl4TCGDx~DYmebhqr4B8Tnt3X35u7r4aDM9vtxPxLOu0E8pNohqX997aOsQZAyYEx1Xqk0PDOPaeVhJFoCaPFsEgNikyC5jKdkD0cMiA6G1A1Dd3hABzalf7EKojWoa3jDzubXhcrEPfmITb7ttscCvS60DjqSVBmlPc3FDjudZlEBjtw65upcp1LlhWS3nWmsKjuQwcx222cbTNF1qSIzEeEeMwa1sgotprfp1DtoGmKaEdx00UyIJ5-wXG4QRwPvRPIYtMcFWqQsu~K~EX9FZPL5NDLzHYSw7xslBQJOjlm4A__) no-repeat center",
              backgroundRepeat: "round",
            }}
          >
            <PublishCard />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ paddingTop: "0 !important" }}
          >
            <Box width={"fit-content"} mt={6}>
              <Typography
                variant="body1"
                sx={{
                  my: 2,
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#263238",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  ":hover": {
                    color: "#0066EE",
                  },
                }}
                onClick={getUserLocation}
              >
                <TbCurrentLocation
                  size={20}
                  color="#0066EE"
                  style={{ marginRight: "8px" }}
                />
                Me géolocaliser
              </Typography>
            </Box>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "72vh",
                  borderRadius: "16px",
                  marginTop: "30px",
                }}
                center={center}
                zoom={5}
                onClick={onMapClick}
                onLoad={(map) => {
                  setMap(map);
                }}
                options={{
                  gestureHandling: "greedy",
                  minZoom: 6,
                  maxZoom: 21,
                  restriction: {
                    latLngBounds: moroccoBounds,
                    strictBounds: false, // Allow panning outside the bounds (optional)
                  },
                  styles: [
                    {
                      featureType: "administrative",
                      elementType: "geometry",
                      stylers: [
                        {
                          visibility: "off", // Hide administrative boundaries
                        },
                      ],
                    },
                    {
                      featureType: "administrative.country",
                      elementType: "labels",
                      stylers: [
                        { visibility: "off" }, // Hide all country labels
                      ],
                    },
                  ],
                }}
              >
                {marker && <Marker position={marker} />}
              </GoogleMap>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* Button */}
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="outlined"
            disabled={marker === null ? true : false}
            sx={{
              minWidth: { xs: "100%", sm: "100%", md: "150px", lg: "150px" },
              minHeight: { xs: "56px", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: marker === null ? "#DAE3EB" : "#0066EE",
              color: marker === null ? "#DAE3EB" : "#FFFFFF",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={() =>
              marker === null
                ? toast.error("Vous devez sélectionner une rue ou une adresse.")
                : handleNext()
            }
          >
            Suivant
          </Button>
        </Box>
      </Box>
      {/* Button */}
    </>
  );
}
