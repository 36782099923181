import axios from "axios";
import { toast } from "react-toastify";

export const serverUrl = process.env.REACT_APP_API_URL;

// Get Api Handler =>
export const getApiRequest = async (endPoint) => {
  const token = localStorage.getItem("token")
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    : null;
  try {
    const res = await axios.get(serverUrl + endPoint, token);
    if (res?.data?.status === 401) {
      toast.error(res?.data?.message);
      setTimeout(() => {
        localStorage.removeItem("token");
        window.location.assign(`${process.env.REACT_APP_WEB_URL}/sign-in`);
      }, 1500);
    } else {
      return res.data;
    }
  } catch (err) {
    console.log("err", err.response);
    const { status } = err.response.data;
    const message = "Quelque chose s'est mal passé";
    return { status, message };
  }
};

// Post Api Handler =>
export const postApiRequest = async (endPoint, data) => {
  const token = localStorage.getItem("token")
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    : null;
  try {
    const res = await axios.post(serverUrl + endPoint, data, token);
    if (res?.data?.status === 401) {
      toast.error(res?.data?.message);
      setTimeout(() => {
        localStorage.removeItem("token");
        window.location.assign(`${process.env.REACT_APP_WEB_URL}/sign-in`);
      }, 1500);
    } else {
      return res.data;
    }
  } catch (err) {
    const { status } = err.response.data;
    const message = "Quelque chose s'est mal passé";
    return { status, message };
  }
};

// Update Api Handler =>
export const putRequest = async (endPoint, data) => {
  const token = localStorage.getItem("token")
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    : null;
  try {
    const res = await axios.put(serverUrl + endPoint, data, token);
    if (res?.data?.status === 401) {
      toast.error(res?.data?.message);
      setTimeout(() => {
        localStorage.removeItem("token");
        window.location.assign(`${process.env.REACT_APP_WEB_URL}/sign-in`);
      }, 1500);
    } else {
      return res.data;
    }
  } catch (err) {
    console.log("errors=>", err);
    const { status } = err.response.data;
    const message = "Quelque chose s'est mal passé";
    return { status, message };
  }
};

// Delete Api Handler =>
export const deleteRequest = async (endPoint) => {
  const token = localStorage.getItem("token")
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    : null;
  try {
    const res = await axios.delete(serverUrl + endPoint, token);
    if (res?.data?.status === 401) {
      toast.error(res?.data?.message);
      setTimeout(() => {
        localStorage.removeItem("token");
        window.location.assign(`${process.env.REACT_APP_WEB_URL}/sign-in`);
      }, 1500);
    } else {
      return res.data;
    }
  } catch (err) {
    console.log("errors=>", err);
    const { status } = err.response.data;
    const message = "Quelque chose s'est mal passé";
    return { status, message };
  }
};
