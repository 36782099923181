import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { postApiRequest } from "../../apiHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginUser, selectUser } from "../../Redux/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
const schema = yup
  .object()
  .shape({
    firstname: yup.string().required("Le prénom est requis"),
    lastname: yup.string().required("Le nom de famille est obligatoire"),
    telephone: yup
      .string()
      .required("Le numéro de téléphone est requis")
      .matches(
        /^\d{10}$/,
        "Le numéro de téléphone doit contenir exactement 10 chiffres"
      )
      .test(
        "is-numeric",
        "Le nombre doit être non négatif et contenir uniquement des chiffres",
        (value) => !isNaN(value) && Number(value) >= 0
      ),
  })
  .required();
function ProfileUpdate() {
  const dispatch = useDispatch();
  const [civilite, setCivilite] = useState();
  const userData = useSelector(selectUser);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    default: {
      firstname: "",
      lastname: "",
      telephone: "",
    },
  });
  const navigate = useNavigate();
  const getData = async () => {
    setValue("firstname", userData?.firstname);
    setValue("lastname", userData?.lastname);

    setValue("telephone", userData?.telephone);
  };

  useEffect(() => {
    if (userData._id) {
      getData();
    }
  }, [userData._id]);

  const profileOnSubmit = async (value) => {
    const data = { ...value, ...civilite };
    const profileRes = await postApiRequest("/api/update-users-profile", data);
    if (profileRes?.status === 200) {
      toast.success(profileRes?.message);
      dispatch(loginUser(profileRes?.response));
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 1000);
    } else {
      toast.error(profileRes?.message);
    }
  };
  useEffect(() => {
    setCivilite({
      prefix: userData.prefix,
    });
  }, [userData]);
  return (
    <div>
      <Paper
        elevation={3}
        sx={{ p: { xs: 3, sx: 6 }, width: "100%", borderRadius: "13px" }}
      >
        <Typography
          variant="body1"
          sx={{ color: "#00156A", fontWeight: "700" }}
        >
          Mes informations
        </Typography>

        <Box component="form" onSubmit={handleSubmit(profileOnSubmit)}>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Civilité
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Civilite"
              name="Civilite"
              // {...register("prefix")}

              // value={userData?.prefix}
              defaultValue={userData?.prefix}
              onChange={(e) => {
                const selectedPropertyType = e.target.value;
                setCivilite({
                  prefix: selectedPropertyType,
                });
              }}
            >
              <FormControlLabel
                // checked={userData["prefix"] === "M"}
                value="M"
                control={<Radio />}
                label="M."
              />
              <FormControlLabel
                // checked={userData["prefix"] === "Mme"}
                value="Mme"
                control={<Radio />}
                label="Mme"
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Nom
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="HERVIER"
              size="small"
              {...register("firstname")}
              error={!!errors?.firstname}
              helperText={errors?.firstname?.message}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Prénom
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="MAUD"
              size="small"
              {...register("lastname")}
              error={!!errors?.lastname}
              helperText={errors?.lastname?.message}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              E-mail
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              // placeholder="maud.hervier@gmail.com"
              size="small"
              value={userData?.email}
              disabled
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Téléphone
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="0685471254"
              size="small"
              {...register("telephone")}
              error={!!errors?.telephone}
              helperText={errors?.telephone?.message}
            />
          </FormControl>
          <Box sx={{ textAlign: "right", mt: 3 }}>
            <Button
              variant="contained"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              type="submit"
            >
              Mettre à jour
            </Button>
          </Box>
        </Box>
        {/* <SupprimerMonCompte /> */}
      </Paper>
    </div>
  );
}

export default ProfileUpdate;
