import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import { Cube } from "../../../../component/ConstantImagesURL";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { RxDotFilled } from "react-icons/rx";
import VirtualTourDialog from "../../VirtualTourDialog";

export default function AjouterUneVisiteVirtuelle({ formData, setFormData }) {
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);

  const handleInfoBoxToggle = () => {
    setInfoBoxVisible((prev) => !prev);
  };

  const handleClickAway = () => {
    setInfoBoxVisible(false);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : value,
    }));
  };

  return (
    <Box>
      <Grid
        component="form"
        autoComplete="off"
        sx={{
          // width: "calc(80% + 24px) !important",
          "& .MuiOutlinedInput-root": {
            borderRadius: "22.5px",
            background: "#FFFFFF",
            "& fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
          },
        }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            variant="body1"
            fontSize={32}
            fontWeight={600}
            color={"#263238"}
          >
            Ajouter une visite virtuelle
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box display="inline" position="relative">
                <IconButton onClick={handleInfoBoxToggle} aria-label="info">
                  <HiOutlineInformationCircle size={22} color="#849FBA" />
                </IconButton>
                {infoBoxVisible && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      boxShadow: "0 5px 5px 0 rgba(0, 94, 220, 0.12)",
                      borderRadius: 4,
                      p: "28px",
                      zIndex: 1000,
                      width: { xs: "200px", sm: "250px", md: "375px" },
                      top: 0,
                      left: "50%",
                      transform: "translate(-50%, 45px)",
                      "::before": {
                        content: '""',
                        position: "absolute",
                        top: "-19px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        borderWidth: "10px",
                        borderStyle: "solid",
                        borderColor: "transparent transparent #fff transparent",
                        zIndex: 1000,
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      mb={2}
                      sx={{
                        svg: { fontSize: "40px", paddingBottom: "20px" },
                      }}
                    >
                      <RxDotFilled />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter, sans-serif",
                          ml: 1,
                        }}
                      >
                        Les annonces immobilières avec des visites virtuelles
                        peuvent obtenir jusqu'à{" "}
                        <b style={{ color: "#0066EE" }}>60% de vues</b> en plus
                        que celles sans.
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      sx={{
                        svg: { fontSize: "40px", paddingBottom: "20px" },
                      }}
                    >
                      <RxDotFilled />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter, sans-serif",
                          ml: 1,
                        }}
                      >
                        Les propriétés avec des visites virtuelles peuvent se
                        vendre jusqu'à{" "}
                        <b style={{ color: "#0066EE" }}>
                          3 fois plus rapidement
                        </b>{" "}
                        que celles sans visite virtuelle.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: "12px", color: "rgba(38, 50, 56, 0.75)" }}
            mt={3}
          >
            Lien vers votre visite 3D
          </Typography>
          <TextField
            fullWidth
            placeholder="Ajouter une visite virtuelle"
            id="outlined-start-adornment"
            name="virtual_visit"
            value={formData?.virtual_visit || ""}
            onChange={handleInput}
            sx={{
              "& .MuiOutlinedInput-root": {
                mt: "7px",
                padding: "0px 16px",
                borderRadius: "22.5px",
                "& fieldset": {
                  borderColor: "rgba(132, 159, 186, 0.45)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(132, 159, 186, 0.45)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(132, 159, 186, 0.45)",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} gap={3} my={2}>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              padding: "22px 32px",
              background: "#FFFFFF",
              borderRadius: "16px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <img src={Cube} alt="Cube" />
                <Typography
                  variant="body1"
                  fontSize={14}
                  color={"#263238"}
                  ml={1}
                >
                  Souhaitez-vous créer votre visite virtuelle ?<br />
                  Contactez-nous pour démarrer dès maintenant !
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                  },
                  mt: { xs: 2, sm: 2, md: 2, lg: 0 },
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <VirtualTourDialog
                  virtualTour={
                    "https://kuula.co/share/N28FK?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
                  }
                />
                <Button
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_WEB_URL + "/contactez-nous"
                    )
                  }
                  size="small"
                  variant="outlined"
                  sx={{
                    minWidth: { xs: "100%", sm: "180px" },
                    minHeight: { xs: "85%", sm: "45px" },
                    px: { xs: 3, sm: 5 },
                    textWrap: "nowrap",
                    fontFamily: `"Inter",sans-serif`,
                    fontSize: 16,
                    fontWeight: 500,
                    borderRadius: "28px",
                    border: "none !important",
                    background: "#0066EE",
                    color: "#FFFFFF",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#0066EE",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Contactez-nous
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
