import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { BiCubeAlt } from "react-icons/bi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VirtualTourDialog({ virtualTour }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          minWidth: { xs: "100%", sm: "180px" },
          minHeight: { xs: "100%", sm: "45px" },
          textWrap: "nowrap",
          px: { xs: 3, sm: 5 },
          fontFamily: `"Inter",sans-serif`,
          fontSize: 16,
          fontWeight: 500,
          borderRadius: "28px",
          border: "none !important",
          background: "#DCECFE",
          color: "#0066EE",
          boxShadow: "none",
          "&:hover": {
            background: "#DCECFE",
            color: "#0066EE",
          },
        }}
      >
        Voir un exemple
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <AppBar
          sx={{
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            backdropFilter: "blur(5px)",
            zIndex: 2,
          }}
        >
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "#ffffff",
                fontSize: "22px !important",
                marginLeft: 0,
              }}
              variant="h6"
              component="div"
            >
              Visite 3D
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
            <IconButton
              edge="start"
              onClick={handleClose}
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ marginTop: "64px", height: "100vh", overflow: "hidden" }}>
          <iframe
            style={{ width: "100%", height: "100%", border: "none" }}
            // src="https://kuula.co/share/collection/7KvGC?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            // src="https://view.ricoh360.com/20d26dc5-77b2-4e5b-96ab-0ef4ac20f4a5?type=compact"
            src={virtualTour}
            loading="lazy"
            contenteditable="true"
            frameborder="0"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowfullscreen=""
            scrolling="no"
            spellcheck="false"
          ></iframe>
        </Box>
      </Dialog>
    </>
  );
}
