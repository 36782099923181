import React, { useEffect } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import InformationsPrincipalesDuBien from "./InformationsPrincipalesDuBien";
import InformationsSupplémentaires from "./InformationsSupplémentaires";
import InformationsDeContact from "./InformationsDeContact";
import InformationDeLannonce from "./InformationDeLannonce";
import AjouterUneVisiteVirtuelle from "./AjouterUneVisiteVirtuelle";
import { toast } from "react-toastify";
import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
} from "../../../component/ConstantImagesURL";

const logoImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
];

const itemArray = [
  "Appartement",
  "Maison",
  "Terrain",
  "Immeuble",
  "Parking",
  "Bureau",
  "Local Commercial",
  "Fonds de Commerce",
  "Studio",
  "Villa",
  "Riad",
];

const typeOfNews = ["Vente", "Location"];

const excludedTypes = ["terrain", "parking"];

export default function StepTwo({ formData, setFormData, handleClick }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleItemClick = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    handleClick("prev");
  };

  const validateFormData = () => {
    if (!formData.property_type) {
      toast.error("Le Type Bein est un champ obligatoire.");
      return false;
    } else if (!formData.area) {
      toast.error("La Surface affichée est un champ obligatoire.");
      return false;
    } else if (formData.property_type === "terrain" && !formData.zoning) {
      toast.error("Le zonage est un champ obligatoire.");
      return false;
    } else if (
      formData.property_type !== "parking" &&
      formData.property_type !== "terrain" &&
      formData.property_type !== "bureau" &&
      formData.property_type !== "local Commercial" &&
      formData.property_type !== "fonds de Commerce" &&
      formData.property_type !== "studio" &&
      !formData.rooms
    ) {
      toast.error("Les Chambres sont un champ obligatoire.");
      return false;
    } else if (
      formData.property_type !== "parking" &&
      formData.property_type !== "terrain" &&
      !formData.bathrooms
    ) {
      toast.error("La Salle de bain est un champ obligatoire.");
      return false;
    } else if (!formData.phone_code && !formData.phone_number) {
      toast.error("Le Téléphone est un champ obligatoire.");
      return false;
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      toast.error("Le numéro de téléphone doit comporter 10 chiffres.");
      return false;
    } else if (!formData.price) {
      toast.error("Le Prix est un champ obligatoire.");
      return false;
    } else if (!formData.title) {
      toast.error("Le Titre de l’annonce est un champ obligatoire.");
      return false;
    } else if (!formData.description) {
      toast.error("La Description est un champ obligatoire.");
      return false;
    } else if (formData.floors_level > formData.floors_count) {
      toast.error(
        "L'étage ne peut pas dépasser le nombre d'étages du bâtiment"
      );
      return false;
    } else if (
      formData.virtual_visit &&
      !/^(https:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?.*$/.test(
        formData.virtual_visit
      )
    ) {
      toast.error("Le lien est invalide.");
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateFormData()) {
      handleClick("next");
    }
  };

  return (
    <>
      <Container
        sx={{
          width: "90%",
          gap: 5,
          mt: 4,
          mb: 12,
        }}
        className="stepTwo"
      >
        <Box bgcolor={"#EDF1F5"} borderRadius={3} px={5} py={8}>
          <Box>
            <Typography
              variant="body1"
              fontSize={32}
              fontWeight={700}
              color={"#263238"}
            >
              Détails de l’annonce
            </Typography>
            <Typography variant="body1" fontSize={16} color={"#263238"} mt={1}>
              Ajouter plus de détails sur votre annonce pour un maximum de
              visibilité
            </Typography>
          </Box>
          <Box mt={6}>
            <Typography
              variant="body1"
              fontSize={24}
              fontWeight={600}
              color={"#263238"}
            >
              Type d’annonce
            </Typography>
            <Box display={"flex"} gap={1} mt={3}>
              {typeOfNews.map((type, index) => (
                <Box
                  key={index}
                  width={260}
                  height={160}
                  bgcolor={
                    formData.ads_type === (index === 0 ? "sale" : "rent")
                      ? "#0066EE"
                      : "#FFFFFF"
                  }
                  onClick={() =>
                    setFormData({
                      ...formData,
                      ads_type: index === 0 ? "sale" : "rent",
                    })
                  }
                  border={0.5}
                  borderColor={"#FFFFFF"}
                  borderRadius={8}
                  gap={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    transition: "border-color 0.2s ease",
                    width: { xs: "100%", sm: 180 },
                    height: { xs: 55, sm: 55, md: 55, lg: 55 },
                    cursor: "pointer",
                    "&:hover": {
                      borderColor: "#0066EE",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={16}
                    fontWeight={500}
                    display={"flex"}
                    color={
                      formData.ads_type === (index === 0 ? "sale" : "rent")
                        ? "#FFFFFF"
                        : "#263238"
                    }
                  >
                    {type}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box textAlign="left" mt={6}>
            <Typography
              variant="body1"
              fontSize={24}
              fontWeight={600}
              color={"#263238"}
            >
              Type de bien
            </Typography>
          </Box>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Grid container spacing={4}>
              {logoImages.map((logo, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <Box
                    bgcolor={
                      formData.property_type === itemArray[index].toLowerCase()
                        ? "#0066EE"
                        : "#FFFFFF"
                    }
                    border={0.5}
                    borderColor={"#FFFFFF"}
                    borderRadius={8}
                    gap={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      transition: "border-color 0.2s ease",
                      width: { xs: "100%", sm: "100%", md: "100%", md: "100%" },
                      height: { xs: 55, sm: 55, md: 55, lg: 55 },
                      cursor: "pointer",
                      "&:hover": {
                        borderColor: "#0066EE",
                      },
                    }}
                    onClick={() =>
                      handleItemClick(
                        "property_type",
                        itemArray[index].toLowerCase()
                      )
                    }
                  >
                    <img
                      src={logo}
                      alt={`Logo ${index + 1}`}
                      style={{
                        filter:
                          formData.property_type ===
                          itemArray[index].toLowerCase()
                            ? "brightness(0) invert(1)"
                            : "none",
                        width: "22px",
                        height: "22px",
                        display: "block",
                      }}
                    />
                    <Typography
                      variant="body1"
                      fontSize={16}
                      fontWeight={500}
                      color={
                        formData.property_type ===
                        itemArray[index].toLowerCase()
                          ? "#FFFFFF"
                          : "#263238"
                      }
                      sx={{ ml: 1 }}
                    >
                      {itemArray[index]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            <InformationsPrincipalesDuBien
              formData={formData}
              setFormData={setFormData}
              handleItemClick={handleItemClick}
            />
          </Box>
          <Box>
            {!excludedTypes.includes(formData["property_type"]) && (
              <InformationsSupplémentaires
                formData={formData}
                setFormData={setFormData}
                handleItemClick={handleItemClick}
              />
            )}
          </Box>
        </Box>
        <Box bgcolor={"#EDF1F5"} borderRadius={3} px={5} py={8} mt={3}>
          <InformationsDeContact
            formData={formData}
            handleItemClick={handleItemClick}
          />
        </Box>
        <Box bgcolor={"#EDF1F5"} borderRadius={3} px={5} py={8} mt={3}>
          <InformationDeLannonce
            formData={formData}
            setFormData={setFormData}
            handleItemClick={handleItemClick}
          />
        </Box>
        <Box bgcolor="#EDF1F5" borderRadius={3} px={5} py={4} mt={3}>
          <AjouterUneVisiteVirtuelle
            formData={formData}
            setFormData={setFormData}
            handleItemClick={handleItemClick}
          />
        </Box>
      </Container>
      {/* Button */}
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Button
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleBack}
          >
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleNext}
          >
            Suivant
          </Button>
        </Box>
      </Box>
      {/* Button */}
    </>
  );
}
