import React, { useState } from "react";
import { OtpKit, OtpKitResendCode } from "react-otp-kit";
import "react-otp-kit/dist/index.css";
import "./index.css";
import { getApiRequest } from "../../../apiHandler";
import { Box, Button, Container, Typography } from "@mui/material";
import { loginUser } from "../../../Redux/reducer/reducer";
import { useDispatch } from "react-redux";

export default function StepOtpVerification({
  user,
  submitData,
  onVerificationSuccess,
  sendOtp,
}) {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(true);

  const handleChange = async (newOtp) => {
    setOtp(newOtp);
    setErrorMessage("");
    setIsOtpValid(true);
    const otpLength = 6;
    if (newOtp.length === otpLength) {
      try {
        const otpVerification = await getApiRequest(
          `/api/verify-otp/${newOtp}/${user?._id}`
        );
        if (otpVerification?.status === 200) {
          setErrorMessage("");
          onVerificationSuccess();
          const updatedUser = { ...user, email_verification: true };
          dispatch(loginUser(updatedUser));
          submitData(true);
        } else {
          setErrorMessage(otpVerification?.message);
          setIsOtpValid(false);
          setOtp("");
        }
      } catch (error) {
        console.error("Failed to verify OTP:", error);
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      await sendOtp();
      setErrorMessage("");
      setOtp("");
      setIsOtpValid(true);
    } catch (error) {
      console.error("Failed to resend OTP:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Vérification OTP
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom pb={2}>
        Veuillez saisir l'OTP envoyé à votre adresse e-mail.
      </Typography>
      <OtpKit
        value={otp}
        onChange={handleChange}
        type="number"
        separator={{
          show: true,
          value: "",
          intervals: 1,
        }}
        autoSubmit={true}
        submitOtpButton={{ show: false }}
      />

      <Box>
        <Typography
          variant="body2"
          color="error"
          pb={2}
          visibility={errorMessage !== "" ? "visible" : "hidden"}
        >
          {errorMessage}
        </Typography>
        <OtpKitResendCode
          resendOtpButton={{
            localFunctions: handleResendOtp,
            initialCountdown: 59,
            text: "Renvoyer l'OTP",
            className: "resendbutton__styles",
            disabledClassName: "resendbutton__disabled",
          }}
          text={{
            countdown: "Renvoyer l'OTP dans {{countdown}}s",
            text: "Renvoyer l'OTP",
          }}
        />
      </Box>
    </Container>
  );
}
