import React, { useState } from "react";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default function InformationsDeContact({ formData, handleItemClick }) {
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Maroc",
    dialCode: "+212",
    countryCode: "ma",
  });

  const handlePhoneChange = (_, country) => {
    setSelectedCountry(country);
    handleItemClick("phone_code", country.dialCode);
  };

  const handlePhoneNumberChange = (event) => {
    const { name, value } = event?.target;
    if (/^\+?\d*$/.test(value)) {
      if (value?.length === 1 && value !== "+" && value?.length <= 10) {
        handleItemClick("phone_code", selectedCountry?.dialCode);
      }
      handleItemClick(name, value);
    }
  };

  return (
    <Box>
      <Typography
        variant="body1"
        fontSize={32}
        fontWeight={600}
        color={"#263238"}
        gutterBottom
      >
        Informations de contact
      </Typography>
      <Typography variant="body1" fontSize={16} color={"#263238"} gutterBottom>
        Les personnes intéressées prendront contact avec vous en utilisant
        l'adresse e-mail associée à votre compte KI. Il est également recommandé
        d'inclure votre numéro de téléphone dans votre annonce.
      </Typography>
      <Box>
        <FormControl sx={{ mb: 0.5, mt: 3 }} variant="outlined" fullWidth>
          <Typography variant="body1" sx={{ fontSize: "12px" }}>
            Téléphone
            <span style={{ color: "#EC544B" }}>*</span>
          </Typography>
        </FormControl>
        <FormControl sx={{ mb: 1.7 }} variant="outlined" fullWidth>
          <Box
            display={"flex"}
            gap={{ xs: 6, sm: 1, md: 1 }}
            alignItems={"center"}
          >
            <PhoneInput
              className="mobile_input_field info_de_contact"
              country={selectedCountry?.countryCode}
              autoPlaceholder="off"
              enableSearch
              containerStyle={{ width: "32%", marginRight: "-16%" }}
              inputStyle={{
                width: "48%",
                height: "96%",
                background: "#FFFFFF",
              }}
              countryCodeEditable={false}
              separateDialCode={true}
              inputProps={{ required: true }}
              value={String(formData?.phone_code) || "+212"}
              onChange={handlePhoneChange}
            />
            <TextField
              required
              type="tel"
              id="Phone"
              smallsize="small"
              placeholder="6 12 34 56 78"
              name="phone_number"
              value={formData?.phone_number || ""}
              onChange={handlePhoneNumberChange}
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  background: "#FFFFFF",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
}
