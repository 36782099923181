const simplicitySpeed = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/simplicitySpeed.svg`;
const totalFree = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/totalFree.svg`;
const optimalPrecision = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/optimalPrecision.svg`;
const extendedVisibility = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/extendedVisibility.svg`;
const checkIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepFinal/checkIcon.svg`;
const GalleryIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepThree/galleryIcon.svg`;
const img1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img1.svg`;
const img2 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img2.svg`;
const img3 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img3.svg`;
const img4 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img4.svg`;
const img5 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img5.svg`;
const img6 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img6.svg`;
const img7 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img7.svg`;
const img8 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img8.svg`;
const img9 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img9.svg`;
const img10 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img10.svg`;
const img11 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img11.svg`;
const Cube = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/AjouterUneVisiteVirtuelle/cube.svg`;
const LoaderGif = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/loader.svg`;
const avitoLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/avito-logo.webp`;
const marocannonces = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/marocannonces.webp`;
const yakeeyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/yakeey-logo.webp`;
const mubawabLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/mubawab-logo.webp`;
const saroutyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/sarouty-logo.webp`;
const agenzLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/agenz-logo.webp`;
const surfaceIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/surfaceIcon.svg`;
const roomsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/roomsIcon.svg`;
const bathroomsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/bathroomsIcon.svg`;
const logo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/logo.webp`;

export {
  simplicitySpeed,
  totalFree,
  optimalPrecision,
  extendedVisibility,
  checkIcon,
  GalleryIcon,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  Cube,
  LoaderGif,
  avitoLogo,
  marocannonces,
  yakeeyLogo,
  mubawabLogo,
  saroutyLogo,
  agenzLogo,
  surfaceIcon,
  roomsIcon,
  bathroomsIcon,
  logo,
};
