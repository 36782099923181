import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineLogout } from "react-icons/ai";
import userProfile from "../assets/images/userProfile.png";
import { Link, useNavigate } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, selectUser } from "../Redux/reducer/reducer";
import { deleteRequest } from "../apiHandler";
import { toast } from "react-toastify";

export default function UserAction() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    dispatch(loginUser(null));
    localStorage.removeItem("token");
    const logoutRes = await deleteRequest(
      `/api/logout-users-activity/${user.token}`
    );
    if (logoutRes?.status === 200) {
      window.location.replace(
        `${process.env.REACT_APP_WEB_URL}/sign-in?origin=true`
      );
    } else {
      toast.error(logoutRes?.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        <Tooltip
          title="Aide"
          sx={{ cursor: "pointer", display: { xs: "none", md: "flex" } }}
        >
          <Stack
            direction="row"
            spacing={0.7}
            justifyContent="space-between"
            alignItems="center"
          >
            <BsQuestionCircle
              style={{ color: "#2f54eb", fontSize: "1.5rem" }}
            />
            <Typography variant="subtitle1" component={Box}>
              Aide
            </Typography>
          </Stack>
        </Tooltip>
        <Box
          sx={{
            display: {
              xs: "flex",
              lg: "flex",
            },
            alignItems: "center",
            gap: "0px",
          }}
        >
          <Tooltip title="Account settings">
            <IconButton
              component={Box}
              size="large"
              onClick={handleMenu}
              color="#0066ee"
              disableRipple
              sx={{
                p: 0,
              }}
            >
              <Box
                component="img"
                src={userProfile}
                alt="userProfile"
                width={30}
                height={30}
              />
              &nbsp;
              <Box textAlign="left">
                <Typography variant="subtitle2" component={Box}>
                  {user && "Bonjour ,"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component={Box}
                  sx={{
                    fontWeight: "500",
                    lineHeight: "1.2",
                    textTransform: "capitalize",
                  }}
                >
                  {user &&
                    (user?.firstname &&
                      `${user?.firstname
                        ?.charAt(0)
                        .toUpperCase()}${user?.firstname?.slice(1)}`) +
                      (user?.lastname
                        ? ` ${user?.lastname
                            ?.charAt(0)
                            .toUpperCase()}${user?.lastname?.slice(1)}`
                        : "")}
                </Typography>
              </Box>
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              to="/user/mon-compte"
              sx={{ color: "#212529" }}
            >
              {" "}
              <BiUserCircle
                style={{ fontSize: "1.5rem", marginRight: "0.7rem" }}
              />{" "}
              Mon compte
            </MenuItem>
            <MenuItem sx={{ color: "#FE0F0F" }} onClick={() => logout()}>
              <AiOutlineLogout
                style={{ fontSize: "1.4rem", marginRight: "0.7rem" }}
              />
              Déconnexion
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );
}
