import React, { useState, useRef } from "react";
import "./index.css";
import {
  Alert,
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { GoCheckCircle } from "react-icons/go";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { GalleryIcon } from "../../../component/ConstantImagesURL";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/reducer/reducer";
import { generateImageHash } from "../../../utils/utils";

const ImageTag = styled("div")(({ _ }) => ({
  position: "absolute",
  top: "86%",
  left: "58%",
  padding: "2px",
  width: "73%",
  transform: "translate(-50%, -50%)",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: `clamp(10px, 2vw, 12px)`,
  backgroundColor: "rgba(38, 50, 56, 0.75)",
  textWrap: "nowrap",
  borderRadius: "8px",
}));

export default function DragDropFile({
  formData,
  setFormData,
  handleClick,
  submitData,
}) {
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");
  const [errorTerms, setErrorTerms] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      handleFiles(droppedFiles);
      setError("");
      setErrorTerms("");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      handleFiles(selectedFiles);
      setError("");
      setErrorTerms("");
    }
  };

  const handleFiles = async (newFiles) => {
    const s3_images_url = formData.s3_images_url || [];
    const imageHashes = [...(formData.imagehash || [])];
    for (const newFile of newFiles) {
      const isDuplicate = s3_images_url.some(
        (file) => file.name === newFile.name
      );
      if (!isDuplicate) {
        s3_images_url.push(newFile);
        const hash = await generateImageHash(newFile);
        imageHashes.push(hash);
      }
    }

    setFormData({
      ...formData,
      s3_images_url: s3_images_url,
      imagehash: imageHashes,
    });
    setError("");
    setErrorTerms("");
  };

  const removeFile = (e, fileName, index) => {
    e.preventDefault();
    const s3_images_url = formData.s3_images_url || [];
    const imageIndex = s3_images_url.findIndex(
      (file, idx) => idx === index && file.name === fileName
    );
    if (imageIndex !== -1) {
      const updatedImages = s3_images_url.filter(
        (file, idx) => idx !== imageIndex
      );
      const updatedHashes = formData.imagehash.filter(
        (hash, idx) => idx !== imageIndex
      );

      setFormData({
        ...formData,
        s3_images_url: updatedImages,
        imagehash: updatedHashes,
      });
      setError("");
      setErrorTerms("");
    }
  };

  const handleBack = () => {
    handleClick("prev");
  };
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const getUserData = (event) => {
    const { name, value, checked, type } = event.target;
    setTermsAndConditions(checked);
    checked
      ? setErrorTerms("")
      : setErrorTerms("Les termes et conditions doivent être acceptés");
  };

  const handleFinish = async () => {
    if (!formData.s3_images_url || formData.s3_images_url.length === 0) {
      setError("Veuillez ajouter au moins une image");
    } else if (termsAndConditions === false) {
      setErrorTerms("Les termes et conditions doivent être acceptés");
    } else {
      setError("");
      setErrorTerms("");
      setLoading(true);
      try {
        await submitData();
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          gap: 5,
          margin: "32px 0 110px 0",
        }}
        className="stepTwo"
      >
        <Box
          id="form-file-upload"
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <Box>
              <img src={GalleryIcon} alt="Gallery Icon" />
              <Typography mt={2}>
                Déposez votre image ici, ou{" "}
                <span
                  style={{ fontSize: 18, fontWeight: 500, color: "#0066EE" }}
                >
                  parcourez
                </span>
              </Typography>
              <Typography>
                Supportent les formats JPG, JPEG2000, PNG, WebP...
              </Typography>
              <Typography>(Limite de 30Mo / Photo)</Typography>
            </Box>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              pt={6}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {formData.s3_images_url &&
                formData.s3_images_url.map((file, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    lg={2.4}
                    sx={{ position: "relative" }}
                    key={index}
                  >
                    <img
                      src={
                        typeof file === "object"
                          ? URL.createObjectURL(file)
                          : `https://content.1ki.ma/${file}`
                      }
                      alt={`preview ${index}`}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "14px",
                      }}
                    />

                    {index === 0 && <ImageTag>Image principale</ImageTag>}
                    <Box
                      style={{
                        position: "absolute",
                        top: "14px",
                        right: "2px",
                        cursor: "pointer",
                        zIndex: "999",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        width: "18px",
                        height: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoClose
                        color="#FFFFFF"
                        size={"14px"}
                        onClick={(e) => removeFile(e, file.name, index)}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </label>
          {dragActive && (
            <Box
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </Box>
        {error !== "" && (
          <Typography textAlign={"left"} color={"#EC544B"} my={1}>
            {error}
          </Typography>
        )}

        <Alert
          severity="info"
          style={{
            marginTop: "16px",
            backgroundColor: "#DCECFE",
            color: "#0066EE",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: "8px",
          }}
          iconMapping={{
            info: <HiOutlineInformationCircle color={"#0066EE"} size={25} />,
          }}
        >
          Réorganisez les photos pour modifier la couverture
        </Alert>
        <Alert
          severity="info"
          style={{
            marginTop: "16px",
            backgroundColor: "transparent",
            color: "#263238",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: "8px",
            padding: 0,
          }}
          iconMapping={{
            info: <GoCheckCircle color={"#5EC38D"} size={22} />,
          }}
        >
          Pour plus de visibilité choisissez comme photo principale une belle
          photo du séjour ou la façade de la maison.
        </Alert>
        <Alert
          severity="info"
          style={{
            backgroundColor: "transparent",
            color: "#263238",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: "8px",
            padding: 0,
          }}
          iconMapping={{
            info: <MdDoNotDisturbAlt color={"#EC544B"} size={22} />,
          }}
        >
          Les photos sombres (lumière éteinte, rideaux fermés...) n’attirent pas
          les visiteurs.
        </Alert>
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "15px",
              color: "#263238",
              marginLeft: "4px",
              marginTop: "8px",
            },
            display: "flex",
            alignItems: "center",
          }}
          control={
            <Checkbox
              onChange={getUserData}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                  marginTop: "8px",
                  color: "#0066EE",
                  marginLeft: "5px",
                  borderRadius: "20px",
                  padding: "0px !important",
                },
              }}
              name="terms_and_conditions"
            />
          }
          label={
            <Typography sx={{ p: 0, m: "4px 0px 0px", fontSize: "14px" }}>
              En postant une annonce sur notre plateforme, vous acceptez nos{" "}
              <a
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_WEB_URL +
                      "/mes-annonces-termes-et-conditions"
                  )
                }
              >
                <b>conditions générales.</b>
              </a>
            </Typography>
          }
        />
        {errorTerms !== "" && (
          <Typography textAlign={"left"} color={"#EC544B"}>
            {errorTerms}
          </Typography>
        )}
      </Container>
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Button
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleBack}
          >
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="outlined"
            sx={{
              minWidth: { xs: "25%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: "#DCECFE",
              color: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={handleFinish}
            disabled={loading}
          >
            Publier
          </Button>
        </Box>
      </Box>
    </>
  );
}
