import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import { formatDate, formatPrice } from "../../utils/utils";
import { FaCircleCheck } from "react-icons/fa6";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  avitoLogo,
  yakeeyLogo,
  mubawabLogo,
  saroutyLogo,
  agenzLogo,
  logo as KIlogo,
  marocannonces,
} from "../../component/ConstantImagesURL";
import { getApiRequest } from "../../apiHandler";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/reducer/reducer";
import AlertDialog from "../BiensEstimes/deletePopup";

export default function ProjectDaChatCards({
  wishlist,
  wishlistId,
  setWishlistId,
  getProjectDaChatData,
  setPrice,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector(selectUser);
  const itemsPerPage = 3;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleCards = wishlist.slice(startIndex, endIndex);

  const websiteLogos = {
    "avito.ma": avitoLogo,
    "yakeey.com": yakeeyLogo,
    "mubawab.ma": mubawabLogo,
    "sarouty.ma": saroutyLogo,
    "agenz.ma": agenzLogo,
    "ki.ma": KIlogo,
    "marocannonces.com": marocannonces,
  };

  const redirectOnAdsDetail = (id) => {
    const adsDetailsLink = `${process.env.REACT_APP_WEB_URL}/annonce-detail/${id}`;
    window.open(adsDetailsLink);
  };

  const [open, setOpen] = useState(false);
  const [removeWishlistId, setRemoveWishlistId] = useState("");
  const isWishlist = async () => {
    try {
      await getApiRequest(
        `/api/annonce/wishlist/${removeWishlistId}/${user?._id}`
      );
      setOpen(false);
      getProjectDaChatData();
    } catch (err) {
      console.log("isWishlist", err);
    }
  };

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setRemoveWishlistId(id);
  };

  return (
    <div style={{ position: "relative" }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {visibleCards.map((item) => {
          const adsData = JSON.parse(item.adsdata);
          const {
            annoncesWebName,
            images_url,
            property_type,
            rooms,
            area,
            address,
            city,
            price,
            website,
            id,
            s3_images_url,
          } = adsData;

          const logo = websiteLogos[website] || KIlogo;
          const imagesUrl = s3_images_url
            ? s3_images_url.map((image) => `https://content.1ki.ma/${image}`)
            : images_url;

          return (
            <Grid
              item
              key={item?._id}
              xs={12}
              sm={6}
              md={4}
              onClick={() => {
                setWishlistId(item?._id);
                setPrice(price);
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  py: { xs: 1, sm: 2 },
                  px: { xs: 1.5, sm: 2.5 },
                  borderRadius: "10px",
                  maxHeight: "480px",
                  minHeight: "480px",
                  border:
                    wishlistId === item?._id
                      ? "2px solid #20C788"
                      : "1px solid #C9C8C8",
                }}
              >
                <Stack direction="row" spacing={1} mb="8px" alignItems="center">
                  <Avatar
                    onClick={() => redirectOnAdsDetail(id)}
                    alt="annonces Web logo"
                    src={logo}
                    sx={{
                      width: "100px",
                      height: "40px",
                      maxHeight: "45px",
                      borderRadius: "0px",
                      fontSize: "15px",
                    }}
                  />
                  {wishlistId === item?._id && (
                    <FaCircleCheck
                      color="#20C788"
                      size={24}
                      className="project_da_chat"
                    />
                  )}
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    {annoncesWebName}
                  </Typography>
                </Stack>
                <Box
                  onClick={() => redirectOnAdsDetail(id)}
                  component="img"
                  src={imagesUrl[0]}
                  alt=""
                  sx={{
                    borderRadius: "10px",
                    my: 1,
                    width: "100% ",
                    minHeight: "218px",
                    maxHeight: "218px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Box sx={{ mt: 2, width: "100%" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={"5px"}
                    >
                      <Typography
                        variant="h6"
                        className="fontMontserrat"
                        sx={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis ",
                        }}
                        onClick={() => redirectOnAdsDetail(id)}
                      >
                        <span style={{ textTransform: "capitalize" }}>
                          {property_type}
                        </span>
                        {rooms != null
                          ? rooms > 1
                            ? " - " + rooms + " Pièces"
                            : " - " + rooms + " Pièce"
                          : ""}
                        {area == null ? "" : " - " + area + "m²"}
                      </Typography>
                      <FavoriteIcon
                        size={24}
                        cursor="pointer"
                        style={{
                          color: "red",
                        }}
                        onClick={() => handleDeleteOpen(id)}
                      />
                    </Stack>
                    <Typography
                      variant="subtitle1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {address}
                      {city}
                    </Typography>
                    <Typography variant="h5" color="primary.main">
                      {price === null ? 0 + " DH" : formatPrice(price)}
                    </Typography>

                    <Typography variant="subtitle2" sx={{ mt: 1, mb: 0.7 }}>
                      Professionnel
                    </Typography>
                    {/* <Typography variant="subtitle1" sx={{ lineHeight: "15px" }}>
                      {agency_details?.name}
                    </Typography> */}
                    <Typography variant="subtitle1" sx={{ fontWeight: "300" }}>
                      Le {formatDate(item?.last_modified).dateDMY}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      {wishlist?.length > itemsPerPage && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Pagination
            count={Math.ceil(wishlist.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
      <AlertDialog
        open={open}
        setOpen={setOpen}
        deleteDataApi={isWishlist}
        check={
          "Etes-vous sûr de vouloir supprimer ceci des éléments de la liste de souhaits ?"
        }
      />
    </div>
  );
}
